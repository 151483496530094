import React, { useState } from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"

import Box from "@material-ui/core/Box"
import MuiAccordion from "@material-ui/core/Accordion"
import MuiAccordionSummary from "@material-ui/core/AccordionSummary"
import MuiAccordionDetails from "@material-ui/core/AccordionDetails"

const Accordion = withStyles({
  root: {
    borderTop: "0.15rem solid #e7f6fd",
    borderBottom: "0.15rem solid #e7f6fd",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion)

const AccordionSummary = withStyles(theme => ({
  root: {
    backgroundColor: "transparent",
    border: "none",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    fontSize: "1.1rem",
    fontWeight: "600",
    color: theme.palette.primary.main,
    "&$expanded": {
      margin: "0.75rem 0",
    },
  },
  expanded: {},
}))(MuiAccordionSummary)

const AccordionDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    textAlign: "left",
    fontSize: "1.1rem",
    color: theme.palette.primary.main,
  },
}))(MuiAccordionDetails)

const CircularToggle = ({ toggled }) => {
  const toggleClass = toggled ? "toggled" : ""
  return (
    <i className={`expand-icon ${toggleClass}`}>
      <svg height="48px" width="48px">
        <g>
          <line x1="14" x2="34" y1="24" y2="24"></line>
          <line x1="24" x2="24" y1="14" y2="34"></line>
          <circle cx="24" cy="24" r="20"></circle>
        </g>
      </svg>
    </i>
  )
}

const CircularAccordion = ({ data }) => {
  const [expanded, setExpanded] = useState(null)

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  return (
    <>
      {data.map((element, index) => {
        return (
          <Accordion
            square
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
          >
            <AccordionSummary
              aria-controls={`panel${index}d-content`}
              id={`panel${index}d-header`}
            >
              <Box display="flex" alignItems="center">
                <CircularToggle
                  toggled={expanded === `panel${index}`}
                ></CircularToggle>
                {element.title}
              </Box>
            </AccordionSummary>
            <AccordionDetails>{element.content}</AccordionDetails>
          </Accordion>
        )
      })}
    </>
  )
}

CircularAccordion.propTypes = {
  data: PropTypes.array,
}

CircularAccordion.defaultProps = {
  data: [],
}

export default CircularAccordion
export { CircularToggle }
